/* Home CSS */

.tableCont {
  margin: 25px 40px;
}

.tableHead h4 {
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 2px;
  color: red;
  font-weight: 600;
}

.alltableBox {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  padding: 5px;
}

.freeTable {
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 30px 60px;
  color: white;
  background: rgb(25, 235, 25);
  border-radius: 5px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.freeTable h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.freeTable h5 {
  margin-top: 8px;
  margin-bottom: 0;
}

.bookTable {
  margin-top: 5px;
  margin-bottom: 25px;
  padding: 30px 60px;
  color: white;
  background: red;
  border-radius: 5px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.bookTable h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.bookTable h5 {
  margin-top: 8px;
  margin-bottom: 0;
}

/*  */
a {
  color: black;
  text-decoration: none;
}

/*  */
/* Dashboard CSS */

.dashGrid {
  display: grid;
  grid-template-columns: 13fr 47fr 40fr;
  overflow: hidden;
}

.alldashCat {
  height: 83vh;
  overflow-y: scroll;
}

.dashCat {
  padding: 15px;
  color: white;
  background: rgb(128, 121, 121);
  border: 1px solid white;
}

.dashCat:hover {
  background: rgb(18, 219, 18);
}

.dashCat h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
}

.alldashPro {
  display: grid;
  grid-template-columns: 25fr 25fr 25fr 25fr;
  column-gap: 20px;
  height: 73vh;
  overflow-y: scroll;
}

.dashPro {
  display: grid;
  align-items: center;
  height: 80px;
  min-width: 100px;
  margin: 20px 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dashPro:hover {
  color: white;
  background: rgb(18, 219, 18);
}

.dashPro h4 {
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-weight: 600;
  font-size: 15px;
}

.dashPro h5 {
  margin-top: -5px;
  margin-bottom: 0;
  text-align: center;
  font-weight: 700;
  font-size: 15px;
}

.dashPro h5 i {
  font-size: 14px;
}

.alldashDetails {
  border-left: 1px solid rgb(202, 199, 199);
  position: relative;
  height: 83vh;
  overflow-y: hidden;
}

.dashTxt {
  display: grid;
  grid-template-columns: 54fr 37fr 9fr;
  padding: 10px 22px;
  background: rgb(228, 228, 228);
}

.dashTxt h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.orderdashItems {
  height: 58vh;
  overflow-y: scroll;
}

.alldashItems {
  height: 32vh;
  overflow-y: scroll;
}

.dashItems {
  display: grid;
  grid-template-columns: 50fr 42fr 8fr;
  padding: 8px;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.dashInfo h5 {
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.dashInfo h5 i {
  color: red;
  font-size: 16px;
  padding-right: 7px;
}

.dashQty {
  display: flex;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dashQty button {
  font-weight: 600;
  font-size: 20px;
  padding: 2px 8px;
  border: none;
  border-radius: 2px;
  background: rgb(235, 235, 235);
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.dashQty i {
  font-size: 20px;
  padding: 3px 8px;
  margin-left: 6px;
  border: none;
  border-radius: 2px;
  color: white;
  background: rgb(7, 167, 7);
}

.dashQty h5 {
  padding: 2px 10px;
  margin-top: 2px;
  margin-bottom: 0;
}

.dashStatus {
  padding-left: 12%;
}

.dashStatus h5 {
  margin-top: 0;
  margin-bottom: 0;
}

.dashPrice {
  display: flex;
}

.dashPrice i {
  margin-top: 9px;
  margin-right: 1px;
  font-size: 12px;
}

.dashPrice h5 {
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 14px;
}

.dashBot {
  display: grid;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: white;
}

.dashBot select {
  justify-self: center;
  text-align: center;
  width: 150px;
  padding: 3px;
  border-radius: 3px;
  background: white;
}

.dashData {
  display: grid;
  grid-template-columns: 50fr 50fr;
  width: 100%;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.dashRemarks {
  display: grid;
  column-gap: 10px;
  margin: 7px 5px;
}

.dashRemarks select {
  margin-top: 5px;
  width: 95%;
  border-radius: 3px;
  max-height: 25px;
}

.dashRemarks input {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 95%;
  max-height: 25px;
  border-radius: 3px;
  border: 1px solid gray;
}

.dashPayment {
  padding: 5px 15px;
  border-left: 1px solid #000;
}

.dashPayment h4 {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 800;
}

.dashPayment h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
}

.dashPayment label {
  display: flex;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 600;
}

.dashPayment label input {
  width: 80px;
  margin-left: 5px;
  margin-top: -1px;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid gray;
}

.dashInput {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
  margin: 5px;
}

.dashInput input {
  padding: 6px;
  border-radius: 3px;
  border: 1px solid gray;
}

.alldashRadio {
  display: flex;
  justify-content: space-around;
  background: rgb(128, 121, 121);
  margin-top: 5px;
}

.dashRadio {
  padding: 10px;
  color: white;
}

.dashRadio label {
  font-size: 15px;
}

.dashBtn {
  display: flex;
}

.dashBtn button {
  width: 100%;
  padding: 8px;
  font-weight: 600;
  color: white;
  background: red;
  border-radius: 5px;
  border: 2px solid white;
}

.dashBtn button:hover {
  background: rgb(18, 219, 18);
}

.searchInput {
  margin: 10px;
}

.searchInput input {
  width: 95%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

/*  */
/* Mui Tabs */

.css-19kzrtu {
  padding: 0 !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-size: 0.675rem !important;
  width: 50% !important;
  color: black !important;
}

.css-1aquho2-MuiTabs-indicator {
  background: #000 !important;
}

/*  */
/* Invoice CSS */

.invoiceCont {
  background: rgb(243, 243, 243);
  width: 360px;
  padding: 5px;
  overflow: scroll !important;
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-top: 15px;
  border-radius: 5px;
  border: 1px solid rgb(226, 226, 226);
  box-shadow: 0 3px 1px -4px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.invoiceLast h5 {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
}

.invoiceHead {
  padding: 10px;
  text-align: center;
}

.invoiceHead h4 {
  margin-top: 15px;
  margin-bottom: 0;
  font-size: 18px;
}

.invoiceHead h5 {
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
}

.invoiceHead h6 {
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
}

.invoiceBox {
  padding: 0 18px;
}

.invoiceBox hr {
  border: 1px solid black;
}

.invoiceTxt {
  display: grid;
  grid-template-columns: 50fr 40fr 10fr;
  margin: 5px 0;
}

.invoiceTxt h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}

.invoiceBot {
  margin-top: 3px;
}

.invoiceData {
  display: flex;
  justify-content: space-between;
}

.invoiceData h2 {
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 16px;
}

.invoiceData h2 i {
  font-size: 14px;
}

.invoiceData h4 {
  margin-top: 4px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}

.invoiceData h4 i {
  font-size: 11px;
}

.invoiceBot h5 {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px;
  font-weight: 600;
  border-top: 1px solid rgb(206, 202, 202);
}

.invoiceQr {
  width: 90px;
  height: fit-content;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}

.invoiceQrtext h5 {
  margin-top: 1px;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

.invoiceMobile {
  display: flex;
  justify-content: space-between;
  margin: 6px 18px;
  border-top: 2px solid black;
}

.invoiceMobile h4 {
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 12px;
}

.invoiceAllBtn {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.invoiceBtn {
  text-align: center;
  margin-top: 10px;
  margin-right: 10px;
}

.invoiceBtn button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  color: white;
  background: rgb(18, 156, 18);
}

/*  */

.foodHead {
  position: relative;
}

.foodImg {
  background: #000;
}

.foodImg img {
  width: 50%;
  display: flex;
  justify-content: center;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}

.foodInfo {
  position: absolute;
  top: 12px;
  left: 5px;
}

.foodInfo h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 7px;
  color: white;
}

.foodInfo h4 i {
  font-size: 14px;
}

.foodInfo2 {
  position: absolute;
  top: 12px;
  right: 5px;
}

.foodInfo2 h4 {
  margin-top: 0;
  margin-bottom: 0;
  padding: 7px;
  color: white;
}

.foodInfo2 h4 i {
  font-size: 14px;
}

.foodBtn {
  position: absolute;
  top: 15px;
  right: 15px;
  text-align: right;
}

.foodBtn h4 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
}

.foodBtn h4 i {
  font-size: 14px;
}

.foodBtn button {
  margin-top: 10px;
  padding: 10px;
  border: none;
  color: white;
  border-radius: 3px;
  background: red;
}

/*  */

.backBtn i {
  margin: 15px;
  font-size: 26px;
  font-weight: 600;
}

.dateBox {
  display: flex;
  column-gap: 3px;
}

.dateBox input {
  padding: 5px 1px;
  border-radius: 3px;
  border: 1px solid gray;
}

.earnSearch {
  position: relative;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 30px;
}

.earnSearch input {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  border-radius: 30px;
  border: 1px solid gainsboro;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.earnSearch i {
  position: absolute;
  left: 12px;
  top: 11px;
  font-size: 16px;
  color: #888;
}

.earnTable table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 3%;
  text-align: center;
  border-radius: 5px;
  border: 1px solid black;
}

.earnTable table tr th {
  padding: 10px;
  border: 1px solid black;
}

.earnTable table tr td {
  padding: 5px 10px;
  border: 1px solid black;
}

.earnTable table tr td i {
  font-size: 14px;
}

.earnTable table tr td button {
  padding: 5px 10px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  color: white;
  background: green;
}

.totalAmount {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid black;
}

.totalAmount h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.totalAmount h4 i {
  font-size: 14px;
}

/*  */
/* KotOrders CSS */

.kotHead {
  padding: 15px;
  text-align: center;
}

.kotHead h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.kotTable table {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  width: 70%;
  border-radius: 3px;
  border: 1px solid black;
}

.kotTable table thead th {
  padding: 2px;
  text-wrap: nowrap;
}

.kotTable table tbody tr {
  background: #000;
}

.kotTable table tbody td {
  text-align: center;
  padding: 5px;
  color: white;
  border-radius: 3px;
  border: 1px solid black;
}

.kotTable table tbody td button {
  width: 100%;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  color: white;
  background: red;
  border: none;
  border-radius: 3px;
}

.audio {
  display: none;
}

/*  */
/* Expense CSS */

.expenseHead {
  display: grid;
  grid-template-columns: 95fr 5fr;
  padding: 10px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.exHeadfirst {
  display: flex;
}

.exHeadfirst h4 {
  margin-top: 2px;
  margin-bottom: 0;
}

.exHeadfirst i {
  font-size: 24px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 20px;
}

.expenseHead button {
  text-wrap: nowrap;
  padding: 8px 15px;
  font-weight: 600;
  border: none;
  color: white;
  background: green;
  border-radius: 3px;
}

.expensedrop {
  display: grid;
  grid-template-columns: 33fr 33fr 33fr;
  column-gap: 30px;
  padding: 30px;
  margin: 10px 0;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.expensedrop select {
  justify-self: center;
  width: 57%;
  padding: 10px;
  margin: 10px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid gray;
}

.expensedrop input {
  justify-self: center;
  width: 50%;
  padding: 10px;
  text-align: center;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid gray;
}

.expensedrop button {
  width: 57%;
  justify-self: center;
  font-weight: 600;
  color: white;
  background: green;
  margin: 10px;
  border-radius: 5px;
  border: none;
}

.expenseInfo {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.catAdd {
  display: flex;
  justify-content: space-between;
}

.catAdd h4 {
  margin-top: 0;
  margin-bottom: 0;
}

.catAdd i {
  margin-top: 3px;
}

.catAddbox {
  display: grid;
  grid-template-columns: 70fr 30fr;
  margin-top: 20px;
}

.catAddbox input {
  padding: 6px;
  border: 1px solid gray;
  border-radius: 3px;
}

.catAddbox button {
  color: white;
  background: green;
  border: none;
  border-radius: 3px;
}

.catInfo {
  margin-top: 20px;
}

.catInfo h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
}

.minicatInfo {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid gray;
}

.minicatInfo h4 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

/*  */
/* Login CSS */

.loginBox {
  width: 30%;
  padding: 30px 20px;
  margin-top: 110px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  box-shadow: 1px 0px 3px 2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.loginInput {
  display: grid;
  margin: 15px 0;
}

.loginInput label {
  font-size: 15px;
}

.loginInput input {
  padding: 10px;
  margin-top: 3px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid rgb(173, 173, 173);
}

.loginBtn button {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  color: white;
  background: black;
  border-radius: 5px;
  border: 1px solid rgb(173, 173, 173);
}

/*  */

.kotFlex {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  margin: 0 10px;
}
